import React from "react"
import Layout from "../components/layout"

const SuccessPage = ({ location }) => {
  console.log({ location })
  return (
    <Layout>
      <div className="flex flex-col items-center justify-center h-full gap-1 p-8 m-auto text-center lg:w-3/4">
        <div className="grid-cols-2 lg:grid">
          <div className="flex flex-col justify-center max-w-md p-4 bg-white rounded lg:p-8">
            <span className="text-4xl" role="img" aria-label="emoji">
              🙌
            </span>
            <h1 className="mt-8">Your order has been received!</h1>
            <h3 className="text-light-blue-900">
              You'll receive a message from me with payment details :-)
            </h3>
          </div>
          <div className="max-w-md p-8 m-auto mt-8 text-left bg-white border-2 border-dashed rounded lg:mt-0 border-light-blue-800 ">
            <h2 className="text-center">Order summary</h2>
            {location.state?.deliveryResult &&
              Object.entries(location.state.deliveryResult).map(
                ([key, value]) => (
                  <div key={key} className="mb-4 text-lg">
                    {key === "Amount" && `Bento boxes: `}
                    {value}
                  </div>
                )
              )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SuccessPage
